//---- Forms Style ---
//---------------------------
.form-content {
    padding: 8px 16px !important;
    background-color: white;
}

ion-item {
    &.item-interactive.ion-invalid {
        --highlight-background: none;
    }

    &.noBorder {
        --border: unset;
        --border-style: unset;
    }

    &.item-interactive.ion-valid {
        --highlight-background: none;
    }
}

ion-input {
    --placeholder-color: var(--theme-input-placeholder-color) !important;
    --placeholder-opacity: var(--theme-input-placeholder-opacity) !important;
    --placeholder-font-style :  var(--theme-input-placeholder-style) !important;
}

.input-item {
    margin-bottom: 16px;
    --inner-padding-end: 0 !important;
    --border-radius: initial;
    --highlight-color-valid: transparent;
    --highlight-color-invalid: transparent;
    --border-color: var(--theme-input-border-color);
    --color: var(--theme-font-color);

    ion-label {
        color: var(--theme-font-color) !important;
    }

    @media screen and (max-height: 666px) {
        margin-bottom: 4px;
    }
}

span.required {
    margin-left: 4px;
    color: var(--ion-color-danger);
}

input {
    font-size: 16px !important;
    padding: 0;
}

.password {
    width: 100%;

    ion-fab {
        position: absolute;
        right: 2px;
        bottom: 2px;

        ion-fab-button {
            height: 35px;
            width: 35px;
            --background: white;
            border: 1px solid var(--ion-color-primary);
            border-radius: 100%;
            --box-shadow: none;

            i {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--ion-color-primary);
            }
        }
    }
}

izivia-help-text {
    margin-top: 4px;
    margin-bottom: 16px;

    @media screen and (max-height: 666px) {
        margin-bottom: 8px;
    }
}


//---- Input Block Style ---
//---------------------------

.inputBlock {
    border: 1px solid var(--theme-input-border-color);
    color: var(--theme-font-color);
    border-radius: 4px 0 0 4px;
    padding: 0 8px;
    height: 38px;
    width: 70vw;

    &::placeholder {
        font-style: italic;
        font-size: 12px;
        color: var(--theme-input-placeholder-color);
    }

    &.validateButton {
        background: var(--ion-color-secondary);
        border: 1px solid var(--ion-color-secondary);
        border-radius: 0 4px 4px 0;
        width: auto;
        box-shadow: none;

        ion-icon {
            color: white;
            font-size: 25px;
        }
    }

    &:focus,
    &:active {
        outline: none;
        border: 1px solid var(--ion-color-secondary);
        box-shadow: 0 1px 6px rgba(var(--ion-color-secondary-rgb), 0.2);
    }
}


//---- Input Validate Style ---
//---------------------------

.inputValidate {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 24px;
}
