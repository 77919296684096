// ~ EVENT NOTIFICATIONS
// ====================================================================== >


.homeEvent {
    &.title {
        padding: 8px 0;
        margin-bottom: 0;
        font-weight: var(--theme-emphasis-font-weight) !important;
    }

    &-alert {
        &-title {
            display: flex;
            flex-direction: row;
            gap: 5px;
            margin-top: 16px;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.2;
            align-items: center;
        }

        &-icon {
            color: var(--ion-color-secondary);
            font-size: 20px;
        }
    }
    ion-item {
        --inner-border-width: 0;
        margin: 4px 0;
        --background: rgba(255, 255, 255, 0.8);
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 8px;
        --border-radius: 0 4px 4px 0;
    }

    ion-list {
        overflow-y: auto;

    }

    &-inProgress {
        ion-item {
            border-left: 3px solid rgba(var(--ion-color-secondary-rgb), 0.3);
        }

        i {
            color: var(--ion-color-secondary);
            font-size: 40px;
        }

        &-text {
            font-size: 12px;
            font-weight: var(--theme-emphasis-font-weight);
            margin-bottom: 2px;
        }

        &-subtext {
            font-size: 12px;
            color: var(--theme-font-color);
        }

        &-iconDirection {
            opacity: 0.2;
        }

    }

    &-empty {
        font-size: 12px;
        border-left: 3px solid var(--ion-color-primary);
        --padding-start: 10px;
        i {
            color: var(--ion-color-tertiary);
            margin-right: 8px;
            font-size: 40px;
        }
    }
}

.homeButtons {
    margin-top: 24px;
    text-align: center;

    .outlineButton {
        background: white;
    }

    ion-button {
        position: relative;
    }

    ion-icon {
        position: absolute;
        right: 16px;
        margin-left: 24px;
    }
}
