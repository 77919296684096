/**
* Custom CSS Variables for configuring theme appearance / look
* Contains only our own variables. Please use `ionic.variables.scss` if you need to tweak Ionic default/global component style.
**/
:root {
    // ~ APP
    // ====================================================================== >
    --theme-app-background: #F9F9FB;

    // ~ LOGO
    // ====================================================================== >
    --theme-toolbar-logo-width: 80px;

    // ~ FONT
    // ====================================================================== >
    --theme-font-family: 'Work Sans';
    --theme-font-color: var(--ion-color-medium-tint);
    --theme-font-color-rgb: var(--ion-color-medium-tint-rgb);
    --theme-font-color-dark: var(--ion-color-dark);

    --theme-title-font-color: black;

    // ~ HEADER
    // ====================================================================== >
    --theme-background-color-header: var(--ion-color-primary);

    --theme-background-color-icon-info: var(--ion-color-secondary);
    --theme-color-icon-info: white;

    --theme-emphasis-font-weight: 500;
    --theme-emphasis-font-color: var(--ion-color-secondary);

    // ~ CARDS
    // ====================================================================== >
    --theme-cards-border-selected: var(--ion-color-secondary);

    // ~ INPUT
    // ====================================================================== >
    --theme-input-border-color: var(--ion-color-light-shade);
    --theme-input-placeholder-color: initial;
    --theme-input-placeholder-icon-color: initial;
    --theme-input-placeholder-style: initial;
    --theme-input-placeholder-opacity: initial;

    // ~ TOGGLE
    // ====================================================================== >
    --theme-toggle-checked-color: var(--ion-color-primary);

    // ~ STEP INDICATORS
    // ====================================================================== >
    --theme-step-indicator-color: var(--ion-color-light-shade);
    --theme-step-indicator-background: var(--ion-color-light);
    --theme-step-indicator-border: transparent;

    --theme-step-indicator-color-selected: white;
    --theme-step-indicator-background-selected: var(--ion-color-primary);
    --theme-step-indicator-border-selected: transparent;

    --theme-step-indicator-separator: var(--ion-color-light);
    --theme-step-indicator-separator-complete: var(--ion-color-primary);

    // ~ MAP MARKER
    // ====================================================================== >
    --theme-map-marker-available-color: #BBD418;
    --theme-map-marker-busy-color: #00A6D4;
    --theme-map-marker-unusable-color: #ED2440;
    --theme-map-marker-unknown-color: #C6C6C5;

    // ~ HOME - ALERT
    // ====================================================================== >
    --theme-alert-calc-shift: 360px
}
