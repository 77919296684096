@import '../utils/animations/ghostAnimations.scss';

.ghost {
    overflow: hidden;
    pointer-events: none;

    &.large-ghost-height {
        height: 80px !important;
    }

    &.round {
        border-radius: 100%;
    }

    .xlarge-ghost-size, .large-ghost-size, .medium-ghost-size, .xmedium-ghost-size, .small-ghost-size, .xsmall-ghost-size, .ghost-height,
    .ghost-remote-img, .ghost-round {
        background-color: rgba(235, 235, 235, 0.514) !important;
        border-bottom: 2px solid white;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0);
        @include animate-ghost-line;
    }

    .xlarge-ghost-size {
        width: 100% !important;
        height: 20px;
    }

    .large-ghost-size {
        width: 80% !important;
        height: 20px;
    }

    .medium-ghost-size {
        width: 60% !important;
        height: 20px;
    }

    .xmedium-ghost-size {
        width: 40% !important;
        height: 20px;
    }

    .small-ghost-size {
        width: 20% !important;
        height: 20px;
    }

    .ghost-round {
        height: 55px;
        width: 55px !important;
        border-radius: 100%;
        border-bottom: none !important;
    }

    .xsmall-ghost-size {
        width: 10% !important;
        height: 20px;
    }

    .ghost-height {
        height: 40px;
    }

    .ghost-remote-img {
        height: 100%;
        width: 100%;
        border-bottom: 0;
    }

    .ghost-align {
        align-self: center;
    }

    .xlarge-ghost-size, .large-ghost-size, .medium-ghost-size, .xmedium-ghost-size, .small-ghost-size, .xsmall-ghost-size, .ghost-height, .ghost-remote-img::after, .large-ghost-height, .ghost-center {
        content: " ";
        white-space: pre;
    }

    .ghost-hidden {
        display: none;
    }

    .large-ghost-height {
        height: 100px;
    }

    .ghost-center {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    &.large-ghost-height {
        height: 100px;
    }

    &.round {
        border-radius: 100%;
    }

    .mb-5 {
        margin-bottom: 5px;
    }
}
