// Since Angular 10, we must use relative assets path. Using /assets (2nd solution) doesn't work with cordova live browser.
// See also https://stackoverflow.com/a/62619147/2118909

@font-face {
    font-family: 'EDF2020';
    src: url("../../assets/fonts/EDF2020/EDF2020W01-Light.woff2") format("woff2");
    font-weight: 300;
}

@font-face {
    font-family: 'EDF2020';
    src: url("../../assets/fonts/EDF2020/EDF2020W01-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: 'EDF2020';
    src: url("../../assets/fonts/EDF2020/EDF2020W01-Bold.woff2") format("woff2");
    font-weight: 700;
}
