ion-modal {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;

    ion-toolbar {
        // overrides iOS style to remove right space (was: `padding-right: calc(var(--ion-safe-area-right) + 8px);`)
        padding-right: var(--ion-safe-area-right) !important;
        // overrides iOS style to remove space between left border and backbutton (was: `padding-left: calc(var(--ion-safe-area-left) + 8px);`)
        padding-left: var(--ion-safe-area-left) !important;
    }
}
