
.plugPath {
    margin-top: 4px;
    --border-width: 1px;
    --border-color: white;
    --background: white;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.selected {
        --border-color: var(--theme-cards-border-selected);
        border-radius: 4px;
        --border-width: 2px;
        --border-radius: 4px;
        --inner-border-width: 0;
    }
}
