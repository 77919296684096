// ~ IMPORT ALL SASS
// ====================================================================== >
// For more informations on sass organization, to know where to put which files, etc.
// see : http://sass-guidelin.es/fr/#le-pattern-7-1

// If you have doubts, put your declarations at the end of this file. I will move these later.
// ~ ANGULAR
// ---------------------------------------------------------------------- >
// http://ionicframework.com/docs/theming/
// This file is the only stylesheet that is required in order for Ionic components to work properly.
// It includes app specific styles, and allows the color property to work across components.
// If this file is not included the colors will not show up and some elements may not appear properly.
@import '@ionic/angular/css/core.css';

// Applies styles to <html> and defaults box-sizing to border-box. It ensures scrolling behaves like native in mobile devices.
@import '@ionic/angular/css/structure.css';

// Typography changes the font-family of the entire document and modifies the font styles for heading elements. It also applies positioning styles to some native text elements.
@import '@ionic/angular/css/typography.css';

// Makes browsers render all elements more consistently and in line with modern standards. It is based on Normalize.css.
@import '@ionic/angular/css/normalize.css';

// ~ OPTIONAL
// The following set of CSS files are optional and can safely be commented out or removed if the application is not using any of the features.
// ---------------------------------------------------------------------- >

// Adds utility classes to modify the padding or margin on any element
@import '@ionic/angular/css/padding.css';

// Adds utility classes to align the text of an element or adjust the white space based on the breakpoint
@import '@ionic/angular/css/text-alignment.css';

// Adds utility classes to float an element based on the breakpoint and side
// @import '~@ionic/angular/css/float-elements.css';

// Adds utility classes to transform the text of an element to uppercase, lowercase or capitalize based on the breakpoint
// @import '~@ionic/angular/css/text-transformation.css';

// Adds utility classes to align flex containers and items
// @import '~@ionic/angular/css/flex-utils.css';

// ~ UTILS
// ---------------------------------------------------------------------- >

// ~ VENDORS
// ====================================================================== >
@import "styles/vendors/_font-icons";

// ~ FONTS
// ====================================================================== >
@import "styles/fonts/_WorkSans";
@import "styles/fonts/_EDF2020";

// ~ BASE
// ---------------------------------------------------------------------- >
@import "styles/base/_base";
@import "styles/base/_typographie";
@import "styles/base/_variables";

// ~ COMPONENTS (please keep sorted by alphabetic order)
// ---------------------------------------------------------------------- >
@import "styles/components/_alert";
@import "styles/components/_buttons";
@import "styles/components/_card";
@import "styles/components/_content";
@import "styles/components/_footer";
@import "styles/components/_forms";
@import "styles/components/_ghost";
@import "styles/components/_header";
@import "styles/components/_modal";
@import "styles/components/_notifications";
@import "styles/components/_plugPath";
@import "styles/components/_refresher";
@import "styles/components/_segment";
@import "styles/components/_slides";
@import "styles/components/_table";
@import "styles/components/_toast";
@import "styles/components/_toggle";

// ~ LAYOUT
// ---------------------------------------------------------------------- >
