//---- Card style ---
//---------------------------

ion-card {
    background: white !important;
    margin: 0 8px 24px 8px !important;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .12) !important;

    ion-card-header+ion-card-content {
        padding-top: 4px !important;
    }

    /* -- Card content --*/
    ion-card-content {
        padding: 0 !important;
        width: 100%;
        color: var(--theme-font-color);

        .card-content {
            width: 100%;
            border-bottom: 1px solid transparent;
            --border-color: transparent;
            margin-bottom: 4px;
        }
    }

    /* -- Card header --*/
    ion-card-header {
        background: white;
        min-height: 50px;
        padding: 16px;
        margin-bottom: 8px;

        ion-card-title {
            font-size: 18px;
            font-weight: bold;
        }
    }
}

/** when card contains ion-item */
.card-grey {
    background: #f8f8f8 !important;
}


/** -- Empty Card Style -- **/
.emptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;

    p {
        font-size: 14px;
        color: var(--theme-font-color) !important;
        margin-bottom: 16px !important;
        text-align: center;
    }

    img, ion-img {
        width: 100%;
        margin: 4px auto;
    }
    .emptyCardImg {
        width: 50%;
        min-height: auto;
        margin: 4px auto;
    }
}

.emptyCard.emptyCardFix {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 265px) !important;
    margin-bottom: 0;

    img, ion-img {
        width: 50%;
    }
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}
