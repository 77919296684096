//---- Segment Menu style ---   //
//------------------------  //

ion-segment {
    border-radius: 4px;
    /** -- Android Skin -- **/

    ion-label.sc-ion-label-md-h.sc-ion-label-md-s {
        display: flex;
        justify-content: center;
        align-items: center;

        ion-badge {
            display: flex;
            align-items: center;
            margin-left: 12px;
            padding: 4px 8px;
            color: var(--ion-color-secondary);
            border: 1px solid var(--ion-color-secondary);
            background: white;
            font-weight: 600;
        }
    }
}

ion-segment-button.md.segment-button-has-label.segment-button-has-label-only.segment-button-layout-icon-top.ion-activatable.ion-activatable-instant.segment-button-checked {
    ion-badge {
        color: white;
        background: var(--ion-color-secondary);
    }
}

/** -- Ios Skin -- **/
.sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s > ion-segment-button {
    border: 1px solid var(--ion-color-secondary);
    padding: 4px 0;
    min-width: 100px;
    max-width: inherit;

    ion-label {
        display: flex;
    }

    ion-badge {
        display: flex;
        align-items: center;
        margin-left: 12px;
        border: 1px solid var(--ion-color-secondary);
        color: var(--ion-color-secondary);
        background: white;
        font-weight: 600;
    }
}

.sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s > .segment-button-checked, .sc-ion-segment-ios-h.ion-color.sc-ion-segment-ios-s > .segment-button-checked.ion-activated {
    color: white;
    background: var(--ion-color-secondary);

    ion-badge {
        background: white;
        color: var(--ion-color-secondary);
    }
}
