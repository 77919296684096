//---- Footer style ---
//---------------------------

ion-footer {
    ion-toolbar {
        --background: var(--theme-app-background);

        ion-button:not(:only-child) {
            margin: 8px 4px;
        }

        ion-buttons {
            ion-button {
                height: var(--izivia-button-height) !important; // needed to be consistent across app (on Android, 34px is set instead of 45px)

                &:only-child {
                    margin: 8px auto;
                }
            }
        }
    }
}

// if navBarre is present
app {
    ion-footer {
        ion-toolbar {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
