//---- Header style ---
//---------------------------

ion-header {
    text-align: center;

    ion-toolbar {
        ion-title {
            font-size: 1.2rem;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;

            // default padding
            padding-inline-start: 0.5rem;
            padding-inline-end: 0.5rem;
        }

        .iconInfo {
            border-radius: 100%;
            font-size: 30px;

            &.secondary {
                color: white;
                background: var(--ion-color-secondary);
            }

            &.white {
                color: var(--theme-color-icon-info);
                background: var(--theme-background-color-icon-info);
            }
        }

        // back-button size is 2.2rem
        izivia-back-button+ion-title {
            padding-inline-start: 2.3rem;
            padding-inline-end: 2.3rem;
        }
    }
}
