* {
    font-family: var(--theme-font-family), sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
    color: var(--theme-font-color-dark) !important;
    font-family: var(--theme-font-family), sans-serif;
}

a {
    color: var(--ion-color-secondary);
}
