@font-face {
    font-family: 'izivia-mobile';
    src: url('/assets/fonts/izivia-mobile.woff2?csi1dw') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'izivia-mobile' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-faq:before {
    content: "\e911";
}

.icon-euro:before {
    content: "\e900";
}

.icon-outlet:before {
    content: "\e90f";
}

.icon-ellipsis:before {
    content: "\e90e";
}

.icon-consumption:before {
    content: "\e90c";
}

.icon-map:before {
    content: "\e90d";
}

.icon-borne:before {
    content: "\e90b";
}

.icon-empty:before {
    content: "\e90a";
}

.icon-password:before {
    content: "\e901";
}

.icon-calendar:before {
    content: "\e908";
}

.icon-charge:before {
    content: "\e909";
}

.icon-arrow-left:before {
    content: "\e902";
}

.icon-batterie:before {
    content: "\e903";
}

.icon-account-pass:before {
    content: "\e904";
}

.icon-charge1:before {
    content: "\e905";
}

.icon-assistance:before {
    content: "\e906";
}

.icon-justice:before {
    content: "\e907";
}

.icon-localisation:before {
    content: "\61";
}

.icon-account-security:before {
    content: "\64";
}

.icon-account-paiement:before {
    content: "\67";
}

.icon-account-list:before {
    content: "\68";
}

.icon-account-euro:before {
    content: "\69";
}

.icon-account-rs:before {
    content: "\65";
}

.icon-edit:before {
    content: "\6a";
}

.icon-favoris:before {
    content: "\6b";
}

.icon-gps:before {
    content: "\6c";
}

.icon-play:before {
    content: "\6d";
}

.icon-info:before {
    content: "\6e";
}

.icon-not-show:before {
    content: "\6f";
}

.icon-show:before {
    content: "\70";
}

.icon-notify:before {
    content: "\71";
}

.icon-user:before {
    content: "\73";
}

.icon-historic:before {
    content: "\74";
}

.icon-direction:before {
    content: "\e910";
}
