$base-color: rgba(221, 221, 221, 0.644);
$ghost-color: rgba(236, 235, 235, 0);
$animation-duration: 1000ms;
$gradient-offset: 52 + 16;


@mixin background-gradient {
    background-image: linear-gradient(90deg, $base-color 0px, $ghost-color 40px, $base-color 80px);
    background-size: 80vw;
}

@keyframes ghost-lines {
    0%   { background-position: -100px;  }
    40%  { background-position: 40vw;    }
    100% { background-position: 60vw;    }
}

@keyframes ghost-avatar {
    0%        { background-position: -100px + $gradient-offset; }
    40%, 100% { background-position: 140px + $gradient-offset;  }
}

@mixin animate-ghost-avatar {
    @include background-gradient;
    animation: ghost-avatar $animation-duration infinite linear;
}

@mixin animate-ghost-line {
    @include background-gradient;
    animation: ghost-lines $animation-duration infinite linear;
}
