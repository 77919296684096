//---- Button style ---
//---------------------
button[disabled] {
    opacity: 0.2;
    background: var(--theme-disable-button, var(--ion-color-secondary));
}

ion-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & > ion-button {
        --border-radius: var(--izivia-button-border-radius) !important;
        border-radius: var(--izivia-button-border-radius);
        font-size: inherit !important; // needed to be consistent across app (on iOS, 17px is set instead of 16px)
        font-weight: 500 !important; // needed to be consistent across app (on iOS, 400 is set instead of 500)
    }
}

ion-button {
    --border-radius: var(--izivia-button-border-radius);
    border-radius: var(--izivia-button-border-radius);
    --box-shadow: var(--izivia-button-box-shadow);
    height: var(--izivia-button-height);
    width: var(--izivia-button-width);
    text-transform: initial;
    margin: 8px auto;
    font-weight: var(--theme-emphasis-font-weight);

    &.icon-only {
        --padding-start: 0;
        --padding-end: 0;
    }
}

ion-button.ion-color.ion-color-secondary {
    --ion-color-contrast: white !important;
    background: white;
}

.sc-ion-buttons-ios-s ion-button {
    height: var(--izivia-button-height);
}

//---- Link style ---
//--------------------------
.link {
    background-color: rgba(var(--ion-color-secondary-rgb), 0.05);
    padding: 4px 8px;
    border-radius: var(--izivia-button-border-radius);
}

