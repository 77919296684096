//---- Refresher style ---   //
//------------------------  //
ion-refresher {
    .refresher-pulling-text,
    .refresher-pulling-icon,
    .refresher-refreshing-icon {
        color: white !important;
        --color: white;
    }
}
