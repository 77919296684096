// https://github.com/weiweihuanghuang/Work-Sans

// Since Angular 10, we must use relative assets path. Using /assets (2nd solution) doesn't work with cordova live browser.
// See also https://stackoverflow.com/a/62619147/2118909

@font-face {
    font-family: 'Work Sans';
    src: url("../../assets/fonts/Work_Sans/WorkSans-Regular.woff2") format("woff2");
    font-weight:400;
}

@font-face {
    font-family: 'Work Sans';
    src: url("../../assets/fonts/Work_Sans/WorkSans-Medium.woff2") format("woff2");
    font-weight:500;
}

@font-face {
    font-family: 'Work Sans';
    src: url("../../assets/fonts/Work_Sans/WorkSans-Bold.woff2") format("woff2");
    font-weight:700;
}
