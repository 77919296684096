//---- General Info ---
//---------------------------
.title {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.subtitle {
    margin-top: 4px;
}

.noResult,
.illuStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img, ion-img {
        width: 60% !important;
        margin: 0 auto;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: var(--theme-font-color) !important;
        margin: 16px 24px;
    }
}

//---- General Background ---
//---------------------------
ion-content {
    --background: linear-gradient(180deg, var(--ion-color-primary) 50px, var(--theme-app-background) 50px); // important must not be used here in order to correctly display the map
}

// apply this on view with no bottom tabs (menu) with no header with next/previous button
.noMenuNoHeaderView {
    min-height: calc(100vh - 100px);
}

// apply this on view with bottom tabs (menu) with next/previous button with header
.menuButtonsView {
    min-height: calc(100vh - 190px);
}

// apply this on view without bottom tabs (menu) with next/previous button with header
.noMenuView {
    min-height: calc(100vh - 150px - var(--izivia-safe-area-top-default) - var(--izivia-safe-area-bottom));
}

// apply this on view without bottom tabs (menu)
.largeView {
    min-height: calc(100vh - 147px);
}

// apply this on view with bottom tabs (menu)
.bottomMenuView {
    min-height: calc(100vh - 245px);
}

// apply this on view with client tunnel
.tunnelView {
    min-height: calc(100vh - 465px);
}

.fullSize {
    height: 100%;
    width: 100%;
}

.fullWidth {
    width: 100%;
}

ion-list {
    background: none !important;
    padding: 0 !important;

    ion-item {
        --border-radius: 4px;
        --padding-start: 16px;
    }
}
