//---- Alert style ---
//---------------------------

ion-alert {
    .alert-button-group {
        .alert-button:first-child {
            color: var(--ion-color-secondary);
        }
        .alert-button:last-child {
            color: var(--ion-color-primary);
        }
    }
}
