
table {
    thead {
        th {
            padding: 0 40px 6px 0;
            font-weight: var(--theme-emphasis-font-weight);
        }
    }

    tbody {
        td {
            padding: 8px 0;
            font-size: 14px;
            color: var(--theme-font-color);
        }

        .time {
            font-style: italic;
        }
    }
}
