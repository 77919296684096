// Core features
@import '../../../node_modules/swiper/swiper.min.css';

// Declare additional modules here (see https://swiperjs.com/angular#styles)
@import '../../../node_modules/swiper/modules/pagination/pagination.min.css';

// Overrides some styles to match former ion-slides
@import '@ionic/angular/css/ionic-swiper';

.swiper {
    flex-direction: column-reverse;
    display: flex;


    .swiper-pagination {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &.swiper-pagination-bullets {
            bottom: 3px !important;
            margin: 8px 0;
        }

        .swiper-pagination-bullet {
            border-radius: 100%;
            height: 10px;
            width: 10px;
        }

        .swiper-pagination-bullet-active {
            background-color: var(--ion-color-primary);
            box-shadow: 0 0 4px var(--ion-color-primary);
        }
    }
}
