/**
* IZIVIA Custom CSS Variables
* Contains only our own variables. Please use `variables.scss` if you need to tweak Ionic default/global component style.
**/
:root {
    --izivia-safe-area-top: var(--ion-safe-area-top, 22px);
    --izivia-safe-area-top-default: var(--ion-safe-area-top, 0px);
    --izivia-safe-area-left: var(--ion-safe-area-left, 0px);
    --izivia-safe-area-right: var(--ion-safe-area-right, 0px);
    --izivia-safe-area-bottom: var(--ion-safe-area-bottom, 0px);

    --izivia-content-min-height-default: calc(100vh - 150px - var(--izivia-safe-area-top-default) - var(--izivia-safe-area-bottom));
    --izivia-content-min-height-tunnel: calc(100vh - 180px - var(--izivia-safe-area-top-default) - var(--izivia-safe-area-bottom));
    --izivia-content-min-height-narrow: calc(100vh - 200px - var(--izivia-safe-area-top-default) - var(--izivia-safe-area-bottom));

    --izivia-button-box-shadow: 0 12px 20px -12px rgba(var(--ion-color-secondary-rgb), 0.35);
    --izivia-button-border-radius: 4px;
    --izivia-button-height: 45px;
    --izivia-button-width: 90%;

    --izivia-logo-width: 100%;
}
